<template>
  <PageHeader :title="title" />
  <!-- Елемент таблиці
    :objParams - параметри сторінки з фільтрами
    @search - подія пошуку
    :columns - колонки
    :rows - рядки
    :pages - сторінки
    :showpag - пропс на пагінацію
    :dataParams - пропс з інфою по сторінці
    @getdata - подія взяття даних
    @changelimit - подія зміну ліміту
    @changeFilter - подія зміни фільтру
  -->
  <tablecustom 
    :objParams="objParams"
    @search="searchB" 
    :columns="columns" 
    :rows="rows" 
    :pages="objPages"
    :showpag="true" 
    :dataParams="this.dataParams"
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter"
  />

</template>

<script>
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import { Archive } from '@/API.js';
import { storeS } from "@/store";

let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks
  },
  data() {
    return {
      title: "archiveSms",
      dataParams: {
        status: true,
        page: 'sms'
      },
      objParams:{
        page: '1',
        pagelimit: '25',
        search: '',
        sent: 'all',
      },
      objPages: {},
      columns: [
        {
          name: this.$t("Employee"),
          text: "workerName",
          align: "right",
          status: true
        },
        {
          name: this.$t("Message"),
          text: 'text',
          align: "left",
          status: true,
          width: '25px',
          needFixed: true
        },
        {
          name: this.$t("date"),
          text: "sentDatetime",
          align: "right",
          status: true
        },
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true
        },
        {
          name: this.$t("status"),
          text: "sentDatetime",
          align: "right",
          status: true,
          mutate: (item, obj) => this.mutateStatus(item, obj),
        }
      ],
      rows: [],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    // міняємо фільтр і оновлюємо дані
    changeFilter(name, value){
      this.objParams[name] = value
      this.getdata()
    },
    mutateStatus(item, obj) {
      // змінюємо статус повідомлення
      console.log(item, obj)
      switch (obj.debugSentData?.response_status) {
        case "SUCCESS_MESSAGE_SENT":
          return this.$t('done')
        case '': // для невдалого повідомлення (треба чекати)
          return

        default:
          return this.$t('Null')
      }
    },
    getdata(page){
      // отримуємо дані
      this.objParams.page = page != undefined ? page : '1';
      apiArchive.getAllArchiveSms(this.objParams).then(result => {
        if(result.status == 'done'){
          this.objPages = result.data;
          this.rows = result.data.items;
        }
      })
    },
    changelimit: function(limit){
      // змінюємо ліміт пагінації
      this.objParams.pagelimit = limit;
      this.getdata();
    },
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    check() {
      return storeS.checks
    },
  },
};
</script>

<style scoped>
  .tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
  }
  .howto_block {
  margin-left:10px;
  }
  .howto_block i {
  font-size: 23px;
  opacity: 0.5;
  }
  .howto_block i:hover{
  opacity: 1;
  }

  .howto_block {
  position: relative;
  display: inline-block;
  }

  .howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
  }

  .howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
  }

  .howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  }
</style>
